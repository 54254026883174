import React from 'react'

const Navigate = () => {
  return (
    <div>
<nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav ml-auto">
                     {/*<li className="nav-item active">
                        <a className="nav-link" href="/">Home </a>
                    </li>
                   
                    <li className="nav-item active">
                        <a className="nav-link" href="#">Hotels </a>
                    </li>
                     <li className="nav-item">
                        <a className="nav-link" href="#">Flights </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Trains</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Trains</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Cars</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Attractions & Tickets</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Bundle & Save</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Attractions</a>
                    </li>
					<li className="nav-item">
                        <a className="nav-link" href="#">Rewards</a>
                    </li>
					<li className="nav-item">
                        <a className="nav-link" href="#">Deals</a>
                    </li> */}
                </ul>
            </div>
           
       </div>
    </nav>

    </div>
  )
}

export default Navigate