import React, { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import TopbarSearch from './TopbarSearch';
import axios from 'axios';
const PaymentStatus = () => {
  const location = useLocation();
  const [rtnmsg, setRtnmsg] = useState(""); 
  const [ordid, setOrdid] = useState("");
  const [prc, setPrc] = useState(0);
  const [ordstr, setOrdstr] = useState(0);
  const [ibobdata, setIbobdata] = useState(0);
  const navigate = useNavigate();
  const [basefare, setBasefare] = useState(0.00);
  const [servicefare, setServicefare] = useState(0.00);
  const [totalfare, setTotalfare] = useState(0.00);
  const [value, setValue] = useState(null);
  const [responsebook, setResponsebook] = useState(null);
  const [responsebookreturn, setResponsebookreturn] = useState(null);
  //"Please Wait, Ticket is Generating....... "
  const [genString, setGenString] = useState("Wait....."); 
  let passString=sessionStorage.getItem('passString');
    useEffect(() => {
    
      // Check if encResp is available in the request body
      const searchParams = new URLSearchParams(location.search);
      const status = searchParams.get('status');
      const orderId = searchParams.get('orderId');
      //localStorage.setItem('orderId', orderId); 
     // console.log('Payment Status:', status);
      //console.log('Order ID:', localStorage.getItem('orderId'));
      const ibobdata1= localStorage.getItem('ibobdata');
      const storedValue = localStorage.getItem('tokenValue');
      if (storedValue) {
        setValue(storedValue);
      }
      if(ibobdata1)
      {
        setIbobdata(ibobdata1);
      }
      if(status=='Success')
      {
        const mailid=localStorage.getItem('useremail');
        console.log(mailid);
          if (orderId) {
            
              // const apiUrl = "https://b2b.travelxpo.in/api/verify-order"; 
              // const requestData = {
              //   "encryptedOrderId": orderId,
              //   "mailId":mailid
              // };
              //console.log(requestData);
              axios
                  .post("https://b2b.travelxpo.in/api/verify-order", {
                    "encryptedOrderId": encodeURIComponent(orderId),
                    "mailId":mailid
                  })
                  .then((response) => {
                      console.log("API Response:", response.data.status);
                      setOrdstr(response.data.status)
                      setGenString("Please Wait, Ticket is Generating....... ");
                      setPrc(1);
                      setOrdid(orderId);
                      setRtnmsg("Your Payment is "+status)
                  })
                  .catch((error) => {
                      console.error('API Error:', error);
                      setPrc(0);
                      setOrdid("");
                      setRtnmsg("Your Payment is not Verified")
                      setGenString("Sorry !! Please Try Again...... ");
                  });
          } else {
              console.error('Encrypted Response not found in the request body.');
              setPrc(0);
              setOrdid("");
              setRtnmsg("Your Payment is not Verified")
              setGenString("Sorry !! Please Try Again...... ");
          }
         
         

      }
      else{
        setPrc(0);
          setOrdid("");
          setRtnmsg("Your Payment Failed")
          setGenString("Sorry !! Please Try Again...... ");
      }
      
  }, [location.search]);
  useEffect(() => {

		function getData1() {
      const ob1=localStorage.getItem('flightchargeOb');
      const prob1=localStorage.getItem('price');
      const ib1=localStorage.getItem('flightchargeIb');
      const prib1=localStorage.getItem('priceIb');
      const storedValue = localStorage.getItem('tokenValue');
      if (storedValue) {
        setValue(storedValue);
      }
      let baseob1=0;
      let totpriceob1=0;
      let baseib1=0;
      let totpriceib1=0;
      if(ob1)
      {baseob1=ob1;}
      if(ib1)
      {baseib1=ib1;}
      if(prob1)
      {totpriceob1=prob1;}
      if(prib1)
      {totpriceib1=prib1;}
      let totalbasefare=parseFloat(baseob1)+parseFloat(baseib1);
      let totalprice=parseFloat(totpriceob1)+parseFloat(totpriceib1);
      let serviceprice=parseFloat(totalprice)-parseFloat(totalbasefare);
	  
      setBasefare(totalbasefare);
      setServicefare(serviceprice);
      setTotalfare(totalprice);

		  }
		  getData1();
      
	},[]) ;
  const handleButton1Click = async () => {
    const ibobdata2=localStorage.getItem('ibobdata');
   // alert(ibobdata2);
    if(ibobdata2==0)
    {
            const data=JSON.parse(localStorage.getItem('passengerOB'));
           console.log('Ticket..'+JSON.stringify(data));
            
            try {
              const response = await axios.post('https://api.travelxpo.in/auth/b2c/booking', data, {
                headers: {
                  Authorization: `Bearer ${value}`,
                },
              });
            //  alert("ok");
                if(response.data.Response.Error.ErrorCode=="0")
                {
                  setResponsebook(response.data);
                //  alert(response.data);
                  //localStorage.removeItem('tokenValue');
                 // alert(JSON.stringify(response.data));
                  //console.log("............................000000000000");
                  navigate('/booking', { state: { responsebook: response.data } });
                  
                  }
                  else{
                  alert(response.data.Response.Error.ErrorMessage);
                  }
              } catch (error) {
              // Handle errors
              alert(JSON.stringify(error));
              }
      }
      else
      {
        console.log('.................'+ibobdata2);
         const data= JSON.parse(localStorage.getItem('passengerOB'));
         const dataobib=JSON.parse(localStorage.getItem('passengerIB'));
         console.log('TicketOb..'+JSON.stringify(data));
         console.log('TicketIb..'+JSON.stringify(dataobib));
         try {
          const response = await axios.post('https://api.travelxpo.in/auth/b2c/booking', data, {
            headers: {
              Authorization: `Bearer ${value}`,
            },
          });
            //console.log(response.data);
            if(response.data.Response.Error.ErrorCode=="0")
            {
              
                if(response.data.Response.Error.ErrorCode=="0")
                {
                  setResponsebook(response.data);
                 
                      const responseib = await axios.post('https://api.travelxpo.in/auth/b2c/booking', dataobib, {
                      headers: {
                      Authorization: `Bearer ${value}`,
                      },
                       });
                      if(responseib.data.Response.Error.ErrorCode=="0")
                      {
                      setResponsebookreturn(responseib.data);
                   
                          navigate('/bookingRound', { state: { 
                              responsebook: response.data ,
                              responsebookreturn: responseib.data
                          
                          } });

                      }
                      else{
                          alert("Arrival Booking Failed:"+responseib.data.Response.Error.ErrorMessage);
                      } 

                }
                else{
                  alert("Departure Booking Failed"+response.data.Response.Error.ErrorMessage);
                  }
               
              }
              else{
              alert(response.data.Response.Error.ErrorMessage);
              }
          } catch (error) {
          // Handle errors
          console.error(error);
          }
      
      
      
       
      }
         
  };
  const handleButtonClick = async (ordstr, ibobdata, value) => {
    console.log("Orderstr:",ordstr);
    if (ordstr==1) {
      
      const ibobdata2=localStorage.getItem('ibobdata');
      // alert(ibobdata2);
       if(ibobdata2==0)
       {
               const data=JSON.parse(localStorage.getItem('passengerOB'));
              console.log('Ticket..'+JSON.stringify(data));
               
               try {
                 const response = await axios.post('https://api.travelxpo.in/auth/b2c/booking', data, {
                   headers: {
                     Authorization: `Bearer ${value}`,
                   },
                 });
                // alert("ok");
                   if(response.data.Response.Error.ErrorCode=="0")
                   {
                     setResponsebook(response.data);
                   //  alert(response.data);
                     //localStorage.removeItem('tokenValue');
                    // alert(JSON.stringify(response.data));
                     //console.log("............................000000000000");
                     navigate('/booking', { state: { responsebook: response.data } });
                     
                     }
                     else{
                     alert(response.data.Response.Error.ErrorMessage);
                     }
                 } catch (error) {
                 // Handle errors
                 alert(JSON.stringify(error));
                 }
         }
         else
         {
           console.log('.................'+ibobdata2);
            const data= JSON.parse(localStorage.getItem('passengerOB'));
            const dataobib=JSON.parse(localStorage.getItem('passengerIB'));
            console.log('TicketOb..'+JSON.stringify(data));
            console.log('TicketIb..'+JSON.stringify(dataobib));
            try {
             const response = await axios.post('https://api.travelxpo.in/auth/b2c/booking', data, {
               headers: {
                 Authorization: `Bearer ${value}`,
               },
             });
               //console.log(response.data);
               if(response.data.Response.Error.ErrorCode=="0")
               {
                 
                   if(response.data.Response.Error.ErrorCode=="0")
                   {
                     setResponsebook(response.data);
                    
                         const responseib = await axios.post('https://api.travelxpo.in/auth/b2c/booking', dataobib, {
                         headers: {
                         Authorization: `Bearer ${value}`,
                         },
                          });
                         if(responseib.data.Response.Error.ErrorCode=="0")
                         {
                         setResponsebookreturn(responseib.data);
                      
                             navigate('/bookingRound', { state: { 
                                 responsebook: response.data ,
                                 responsebookreturn: responseib.data
                             
                             } });
   
                         }
                         else{
                             alert("Arrival Booking Failed:"+responseib.data.Response.Error.ErrorMessage);
                         } 
   
                   }
                   else{
                     alert("Departure Booking Failed"+response.data.Response.Error.ErrorMessage);
                     }
                  
                 }
                 else{
                 alert(response.data.Response.Error.ErrorMessage);
                 }
             } catch (error) {
             // Handle errors
             console.error(error);
             }
         
         
         
          
         }

     
		  }
		  else
		  {
			console.log("Your Payment is not success");
		  }
  };
  useEffect(() => {
   // alert("Test...");  
    handleButtonClick(ordstr, ibobdata, value);
  }, [ordstr,ibobdata,value]);
  return (
   <div>
    <TopbarSearch />
<section class="bg-white"> 
  <div className="container mb-5">
    <div className="row">
      <div className="col-lg-9">
        <div className="row">
                <div className="col-lg-12 detailsview">
                  <div className="card">
                      <div className="card-body">
                        <h4>TICKET DETAILS</h4>
                      </div>
                      <div className="cardsection">
                        <div className="row">
                          <div className="col-lg-7 col-4">
                           
                          </div>
                          <div className="col-lg-3 col-6 text-end linkssec">
                            <p>Baggage and fare rules</p>
                          </div>
                          <div className="col-lg-2 text-end">
                            <button className="btn btn-dark"> PARTALLY REFUNDABLE</button>
                          </div>
                        </div>
                      </div>
                  </div>
               </div>
          </div>
      </div>

      <div className="col-lg-9">
     
              <div className="row">
                      <div className="col-lg-12 detailsview">
                        <div className="card">
                            <div className="card-body">
                              <h4>Payment Status</h4>
                            </div>
                            <div className="cardsection">
                              <div className="row">
                              <div className="col-lg-7 col-4">
                                  
                                <h5>{rtnmsg}</h5>
                          
                                </div> 
                                
                                <div className="col-lg-2 text-end">
                                <input type="button"  className="btn btn-primary"  variant="contained" color="primary"  onClick={() => handleButtonClick(ordstr, ibobdata, value)}
                                value={genString} />
                                       
                                
                                </div>
                                <br /><br />
                                <input type="button"  className="btn btn-primary"  variant="contained" color="primary" style={{display:"none"}}  onClick={() => handleButton1Click()} value="Test Ticket" />
                              </div>
                            </div>
                        </div>
                     </div>
                </div>
               
        </div>
        
      
      <div className="col-lg-3">
        <div className="asidesection bg-white">
          <div className="card" style={{display:"none"}}>
              <div className="card-body">
                <h4>FARE SUMMARY </h4>
                <p>{passString}	</p>
              </div>
                <div className="pricedetails">
                  <div className="price">
                    <table className="table pricelisttable bg-white table-borderless">
                    <tbody>
                    <tr>
                        <td>Base fare + Tax</td>
                        <td className="text-right">{basefare}</td>
                      </tr>
                     
                      <tr>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Service Charge</td>
                        <td className="text-right">{servicefare}</td>
                      </tr>
                      {/* <tr>
                        <td style={{fontSize: "10px",textAlign: "justify",color: "#000"}}>make a difference by contributing 10 to support & join us in promoting a culture to substainable travel</td>
                        <td> <input className="me-3 mt-3" type="checkbox" name="checkprice" /></td>
                      </tr> */}
                      <tr>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><h4>Grand Total</h4></td>
                        <td><h3>{totalfare}</h3></td>
                      </tr>
                    </tbody>
                    </table>
                  </div>
                </div>
          </div>
          {/* <div className="card mt-4">
            <div className="card-body">
              <h4>TRAVEXPRO OFFERS</h4>
            </div>
            <div className="pricedetails">
              <div className="price">
                <table className="table pricelisttable bg-white table-borderless">
                <tbody>
                  <tr>
                    <td> <input className="mt-3" type="radio" name="checkprice" /></td>
                    <td className="text-left"><h4 className="text-dark">OFFER NAME 1</h4>
                    <p className="mr-2 offers">Use this coupon and get Rs. 321 instant discount on flight booking</p></td>
                  </tr>
                  <tr>
                    <td> <input className="mt-3" type="radio" name="checkprice" /></td>
                    <td className="text-left"><h4 className="text-dark">OFFER NAME 1</h4>
                    <p className="mr-2 offers">Use this coupon and get Rs. 321 instant discount on flight booking</p></td>
                  </tr>
                  <tr>
                    <td> <input className="mt-3" type="radio" name="checkprice" /></td>
                    <td className="text-left"><h4 className="text-dark">OFFER NAME 1</h4>
                    <p className="mr-2 offers">Use this coupon and get Rs. 321 instant discount on flight booking</p></td>
                  </tr>
                  
                </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card mt-4">
            <div className="card-body discount">
              <h4>Apply Discount</h4>
              <p> Have a discount / promo code to redeem</p>
            </div>
            <div className="discountdetails">
              <div className="row">
                <div className="col-lg-9">
                  <input type="text" className="form-control" placeholder="Promo Code" name="discount" />
                </div>
                <div className="col-lg-3">
                  <button className="btn btnapply">Apply</button>
                </div>	
              </div>	
            </div>
          </div> */}
        </div>
      </div>
    </div>
    
  </div>
  
  </section>
  <footer className="section footer-classNameic">
  <div className="container">
    
    <div className="row row-50">
      <div className="col-lg-5 col-sm-6 wow fadeInUp brandlogo">
       <a className="brand" href="index.html"><img className="brand-logo-light" src="images/logo.png" alt="" width="200" height="60" /></a>
      </div>
      <div className="col-lg-4 col-sm-6 d-sm-flex flex-column wow fadeInUp">
        <h6 className="heading-6">useful links</h6>
           <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 ">
                <ul className="list-marked-2">
                  <li><a href="#">Flights</a></li>
                  <li><a href="#">International Flights</a></li>
                  <li><a href="#">Hotels</a></li>
                  <li><a href="#">International Hotels</a></li>
                  <li><a href="#">Domestic Airline</a></li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 ">
                <ul className="list-marked-2">
                  <li><a href="#">Indigo Airline</a></li>
                  <li><a href="#">Air Asia</a></li>
                  <li><a href="#">Spicejet</a></li>
                  <li><a href="#">Go Air</a></li>
                  <li><a href="#">Air India</a></li>
                </ul>
              </div>
          </div>
      </div>
      <div className="col-lg-3 col-sm-6 wow fadeInUp">
        <ul className="d-flex socialmedia">
          <li><a href="#"><img src="images/icons/fb.png" alt="" className="img-fluid" /></a></li>
          <li><a href="#"><img src="images/icons/insta.png" alt="" className="img-fluid" /></a></li>
          <li><a href="#"><img src="images/icons/linkendin.png" alt="" className="img-fluid" /></a></li>
          <li><a href="#"><img src="images/icons/twitter.png" alt="" className="img-fluid" /></a></li>
          </ul>
      </div>
     </div>
  </div> 
  {/* <!--+rights--> */}
 </footer> 
 </div>
  )
}

export default PaymentStatus