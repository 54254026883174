import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
const RedirTo = () => {
    const location = useLocation();
    const responseget = location.state?.responsee;
    const [response,setResponse] = useState(responseget);
    const navigate = useNavigate(); 
    useEffect(() => {
        // Set a delay in milliseconds (e.g., 2000ms for a 2-second delay)
        const delay = 1;
    
        // After the delay, navigate back to /Oneway
        const timeoutId = setTimeout(() => {
           const journeyType =localStorage.getItem('journeyType');
         //  alert(journeyType);
           if(journeyType==1)
           {
            navigate('/Oneway', { state: { responsee: response } });
           }
          else if(journeyType==3)
          {
            navigate('/Multicity', { state: { responsee: response } });
          }
          else if(journeyType==2)
          {
            if(response.Response.Results.length==2)
		    {
            navigate('/RoundTripOB', { state: { responsee: response } });
            }
            else{
                navigate('/RoundTripCombo', { state: { responsee: response } });
            }
          }
        }, delay);
    
        return () => {
          clearTimeout(timeoutId);
        };
      }, [response, navigate]);

  return (

    <div></div>
  )
}

export default RedirTo