import React,{ useState,useEffect,useRef } from 'react';
//import Autocomplete from './Autocomplete';
import TextField from '@mui/material/TextField';
//import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import {
    Grid,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Button,
    FormHelperText,
	Autocomplete,
    CircularProgress 
  } from "@mui/material";

const HotelSearch = () => {
    const today = new Date();
    const currentDate = new Date().toISOString().split('T')[0];
    const [fromdt,setFromdt]=useState(currentDate);

	const [starStyle2, setStarStyle2] = useState({color:"blue",backgroundColor:"white"});
    const [starStyle3, setStarStyle3] = useState({color:"blue",backgroundColor:"white"});
    const [starStyle4, setStarStyle4] = useState({color:"blue",backgroundColor:"white"});
    const [starStyle5, setStarStyle5] = useState({color:"blue",backgroundColor:"white"});
    const [hotel, setHotel] = useState([]);
    const [toCity,setToCity]=useState("");
    const [rooms, setRooms] = useState(1);
    const [adults, setAdults] = useState(1);
	const [children, setChildren] = useState(0);
    const [showdiv, setShowDiv] = useState({display:"none"});
	const [displayStr, setDisplayStr] = useState("1 Room 1 Adult");
    const [roomString,setRoomString]= useState("Room");
    const [adultString,setAdultString]= useState("Adult");
    const [childString,setChildString]= useState("Child");
    const getListH = (list) =>
list.map((item) => ({
  value: item.code,
  label: item.code + " -- "+item.name + " -- "+ item.country, 
}));
useEffect(() => {

	async function fetchDataH() {
      
	  try {
		const response = await fetch("https://b2b.travelxpo.in/api/get_city", {
		  method: "POST",
		  headers: {
			"Content-Type": "application/json",
			// You can add other headers if required
		  },
		  // You may need to send data with your POST request in the body, if necessary
		  // body: JSON.stringify({ key: "value" }),
          
		});
       // alert(JSON.stringify(response.data));
		if (response.ok) {
            alert("jj");  
		  const json = await response.json();
          
		  setHotel(getListH(json));
		} else {
		  // Handle the response when it's not OK (e.g., error handling)
		  console.error("Error fetching data:", response.status, response.statusText);
		}
	  } catch (error) {
        alert(error);
		// Handle network or other errors
		console.error("Network error:", error);
	  }
	}
  
	fetchDataH()
  }, []);
    const handleStar = (number) => {
        return () => {
          if (number === 3) {
            setStarStyle3(currentStyle => ({
              color: currentStyle.color === "blue" ? "white" : "blue",
              backgroundColor: currentStyle.color === "blue" ? "blue" : "white"
            }));
          } else if (number === 4) {
            setStarStyle4(currentStyle => ({
              color: currentStyle.color === "blue" ? "white" : "blue",
              backgroundColor: currentStyle.color === "blue" ? "blue" : "white"
            }));
          } else if (number === 5) {
            setStarStyle5(currentStyle => ({
              color: currentStyle.color === "blue" ? "white" : "blue",
              backgroundColor: currentStyle.color === "blue" ? "blue" : "white"
            }));
          } else {
            setStarStyle2(currentStyle => ({
              color: currentStyle.color === "blue" ? "white" : "blue",
              backgroundColor: currentStyle.color === "blue" ? "blue" : "white"
            }));
          }
        };
      };
const handleDropdownToggle = () => {
    setShowDiv((prevShowDiv) => ({
        display: prevShowDiv.display === "none" ? "block" : "none",
    }));
	  };
const handleDateChange = (date, fieldName) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
};


  return (
    <div><div className="container bookingview p-2">
    <div className="row">
       <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 p-1" >
           <div className="form-group citynames">
            
               <label>Destination City Name</label>
               
               {/* <div className="select_box multiple cmn_InputArrow dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false"> */}
               <Autocomplete
										disablePortal
										id="combo-box-demo"
										className="form-control w-100 "
										value={toCity}
										onChange={(e, newValue) => {
											if (newValue !== null) {
												setToCity(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
											} else {
												setToCity('');
											}
											}}
										
										options={hotel}
										sx={{ width: 350}}
                                        style={{ height: '10px' }} 
										renderInput={(params) => <TextField {...params}  />}
										renderOption={(props, option) => (
											<li {...props} style={{  color: 'grey', fontWeight: '500', fontSize: '0.8rem' ,height :'10px' }}>{option.label}</li>
										  )}
										/> 
                   
               {/* </div> */}
                   <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                       <ul className="mb-0">		
                           <li className="dropdown-item bg-lightview">
                               <h6>Asia</h6>
                           </li>
                           <li className="dropdown-item grid_item">
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                           </li>
                           <li className="dropdown-item bg-lightview">
                               <h6>Asia</h6>
                           </li>
                           <li className="dropdown-item grid_item">
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                           </li>
                           <li className="dropdown-item bg-lightview">
                               <h6>Asia</h6>
                           </li>
                           <li className="dropdown-item grid_item">
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
                               <a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
                           </li>
                           
                       </ul>
                   </div>
           </div>
       </div>
       <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2  p-1" >
           <div className="form-group">
               <label>Check-in</label>
               <div style={{height:"40px",marginTop:"0px",border: "1px solid rgb(216 203 203)",padding: "2px",borderRadius:"5px"}}>
               <DatePicker
               name="checkin"
               value={moment(fromdt).format('DD/MM/YYYY')}
               onChange={(date) => handleDateChange(date, 'fromdt')}
               dateFormat="dd/MM/yyyy"
               minDate={today}
               showYearDropdown
               yearDropdownItemNumber={2} // Set the number of years to show in the dropdown
               placeholderText="Check-in"
               inputProps={{ autoComplete: 'off' }}
               wrapperClassName="date-picker-wrapper" 
               style={{ width: '240px',height:'45px'}}
               />
               </div>
           </div>
       </div>
       <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2  p-1" >
           <div className="form-group">
               <label>Check-out</label>
               <div style={{height:"40px",marginTop:"0px",border: "1px solid rgb(216 203 203)",padding: "2px",borderRadius:"5px"}}>
               <DatePicker
               name="checkout"
               value={moment(fromdt).format('DD/MM/YYYY')}
               onChange={(date) => handleDateChange(date, 'fromdt')}
               dateFormat="dd/MM/yyyy"
               minDate={today}
               showYearDropdown
               yearDropdownItemNumber={2} // Set the number of years to show in the dropdown
               placeholderText="Check-out"
               inputProps={{ autoComplete: 'off' }}
               wrapperClassName="date-picker-wrapper" 
               style={{ width: '240px'}}
               />
               </div>
           </div>
       </div>
       <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3  p-1" >
           <div className="form-group">
               <div className="extraview">
               <label>Rooms and Guests</label>
               
               <div className="select_box cmn_InputArrow dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
               <div style={{height:"40px",marginTop:"0px",border: "1px solid rgb(216 203 203)",padding: "2px",borderRadius:"5px"}}>
                   <input type="text" name="travellersText"  id="selectTravellerFlt" value={displayStr} onClick={handleDropdownToggle} className="travell_input e_input" readonly="readonly" placeholder="1  " autocomplete="none" />
              </div>
               </div>
               <div className="dropdown-menu" aria-labelledby="dropdownMenu2" style={showdiv}>
           <ul className="mb-0">		
               <li>
                   <a className="dropdown-item" href="javascript:void(0);">
                       <div className="row">
                           <div className="col-lg-8">
                               <p className="mb-0">Rooms<br /><small></small></p>
                           </div>
                       
                           <div className="col-lg-4">
                               <div className="qty-input">
                                   <button className="qty-count qty-count--minus" data-action="minus" type="button" 
                                        onClick={() => {setRooms(prevRoom => Math.max(prevRoom - 1, 1));
                                        
                                        setDisplayStr(prevDisplayStr => {
                                            let updatedRooms = Math.max(rooms - 1, 1);
                                            const roomText = updatedRooms === 1 ? 'Room' : 'Rooms';
                                            if(updatedRooms>adults) {
                                                updatedRooms=adults;
                                                setRooms(updatedRooms);
                                            }
                                            setRoomString(roomText);
                                            return `${updatedRooms} ${roomText} ${adults} ${adultString}`;})
                                    }}>-</button>
                                   <input className="product-qty" type="number" name="product-qty" min="0" max="10" value={rooms} readOnly />
                                   <button className="qty-count qty-count--add" data-action="add" type="button" 
                                        onClick={() => {setRooms(prevRoom => Math.min(prevRoom + 1, 10));setRoomString("Rooms"); 
                                        // setDisplayStr(prevDisplayStr => `${Math.min(rooms + 1, 10)} Rooms ${adults} ${adultString}`);
                                        setDisplayStr(prevDisplayStr => {
                                            let updatedRooms = Math.min(rooms + 1, 10);
                                            if(updatedRooms>adults) {
                                                updatedRooms=adults;
                                                setRooms(updatedRooms);
                                            }
                                            const roomText = updatedRooms === 1 ? 'Room' : 'Rooms';
                                            
                                            setRoomString(roomText);
                                            return `${updatedRooms} ${roomText} ${adults} ${adultString}`;})
                                        }}>+</button>
                               </div>
                           </div>
                       </div>	
                   </a>
               </li>
               <li>
                   <a className="dropdown-item" href="javascript:void(0);">
                       <div className="row">
                           <div className="col-lg-8">
                               <p className="mb-0">Adults<br /><small className="gray" style={{color:"grey"}}>18+ yrs</small></p>
                           </div>
                       
                           <div className="col-lg-4">
                               <div className="qty-input">
                                   <button className="qty-count qty-count--minus" data-action="minus" type="button"  
                                   onClick={() => {setAdults(prevAdults => Math.max(prevAdults - 1, 1));
                                   
                                    setDisplayStr(prevDisplayStr => {
                                        let updatedAdults = Math.max(adults - 1, 1);
                                        
                                        if(updatedAdults<rooms) {
                                            updatedAdults=rooms;
                                            setAdults(updatedAdults);
                                        }
                                        const adultText = updatedAdults === 1 ? 'Adult' : 'Adults';
                                        setAdultString(adultText);
                                        return `${rooms} ${roomString} ${updatedAdults} ${adultText}`;})
                                   }}>-</button>
                                   <input className="product-qty" type="number" name="product-qty" min="0" max="10"  value={adults} readOnly />
                                   <button className="qty-count qty-count--add" data-action="add" type="button"  
                                   onClick={() => {setAdults(prevAdults => Math.min(prevAdults + 1, 10));setAdultString("Adults"); setDisplayStr(prevDisplayStr => `${rooms} ${roomString} ${Math.min(adults + 1, 10)} Adults`);}}>+</button>
                               </div>
                           </div>
                       </div>	
                   </a>
               </li>
               <li>
                   <a className="dropdown-item" href="javascript:void(0);">
                       <div className="row">
                           <div className="col-lg-8">
                               <p className="mb-0">Children<br /><small className="gray"  style={{color:"grey"}}>0–18 yrs</small></p>
                           </div>
                       
                           <div className="col-lg-4">
                               <div className="qty-input">
                                   <button className="qty-count qty-count--minus" data-action="minus" type="button"  onClick={() => setChildren(prevChildren => Math.max(prevChildren - 1, 0))}>-</button>
                                   <input className="product-qty" type="number" name="product-qty" min="0" max="10" value={children} readOnly />
                                   <button className="qty-count qty-count--add" data-action="add" type="button"  
                                   onClick={() => {setChildren(prevChildren => Math.min(prevChildren + 1, 10));
                                    setChildString("Child");
                                    setDisplayStr(prevDisplayStr => {
                                        let updatedChildren = Math.min(children + 1, 10);
                                        const childText = updatedChildren === 1 ? 'Child' : 'Children';
                                        setChildString(childText);
                                        return `${rooms} ${roomString} ${adults} ${adultString} ${children} ${childText}`;})
                                   }}>+</button>
                               </div>
                           </div>
                       </div>	
                   </a>
               </li>
               <li>
                   <div className="dropdown-item mb-0 text-end">
                       <div className="cmn_btn">
                           <a className="btn" onClick={handleDropdownToggle}>Done</a>
                       </div>
                   </div>
               </li>
               </ul>
                  </div>
               </div>
           </div>
       </div>
       {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2  p-1" >
           <div className="form-group">
               <label>Keywords (Optional)</label>
               <input type="text" name="txt_booking_refno" maxlength="10" id="txt_booking_refno" value="" className="form-control" placeholder="Airport, station, property name, area" autocomplete="none" />
               
           </div>
       </div> */}
       <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1  p-1" >
           <div className="savecmn_btn">
               <a className="btn"><i className="fa fa-search" aria-hidden="true"> </i> Search</a>
           </div>
       </div>
   </div>
    <div className="row pt-1">
       <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" >
           <div className="checkbox d-flex">
               <input type="checkbox" name="show_sub_agentRate" id="show_sub_agent_rate" value="yes" autocomplete="none" />
               <label for="show_sub_agent_rate" className="checkedsec"> I'm traveling for work <i className="fa fa-info-circle" aria-hidden="true"></i></label>
           </div>
       </div>
       <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
           <div className="star-rate-container"><span className="star-rate">Star Rating</span>
           <span className="rate" style={starStyle2} onClick={handleStar(2)} ><b>≤ 2</b><i className="smarticon far fa-star star-rate-icon"></i></span>
           <span className="rate"  style={starStyle3}  onClick={handleStar(3)}><b>3</b><i className="smarticon far fa-star star-rate-icon"></i></span>
           <span className="rate"  style={starStyle4}  onClick={handleStar(4)}><b>4</b><i className="smarticon far fa-star star-rate-icon"></i></span>
           <span className="rate"  style={starStyle5}  onClick={handleStar(5)}><b>5</b><i className="smarticon far fa-star star-rate-icon"></i></span></div>
       </div>
   </div>
</div></div>
  )
}

export default HotelSearch