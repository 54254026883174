import React,{ useState,useEffect } from 'react';
import axios from 'axios';
import { Link ,Routes,Route,Router,Switch, useNavigate, useLocation } from 'react-router-dom';

const CCAvenueRedirect = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [succe, setSucce] = useState("");
    const [fail, setFail] = useState("");
    //ccavenue/callback
    useEffect(() => {
        // Check if encResp is available in the request body
        const encResponse = location.search.encResp; // Assuming requestData is the POST data object
    
        if (encResponse) {
            console.log('Encrypted Response:', encResponse);
            const apiUrl = "https://b2b.travelxpo.in/api/cca-response"; 
            const requestData = {
                encResp: encResponse, // Assuming encResp is the field name in the POST request
            };
    
            axios
                .post(apiUrl, requestData)
                .then((response) => {
                    console.log("API Response:", response.data);
                })
                .catch((error) => {
                    console.error('API Error:', error);
                });
        } else {
            console.error('Encrypted Response not found in the request body.');
        }
    }, [location.search]);
  return (
    <div>CCAvenueRedirect</div> 
  )
}

export default CCAvenueRedirect