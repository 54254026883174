import React,{ useState,useEffect, useRef,useHistory} from 'react';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
const TopbarSearch = ({ trigger }) => {
const navigate  = useNavigate();
const [showModalL, setShowModalL] = useState(false);
const [selectedRowL, setSelectedRowL] = useState(null);
const useremail=localStorage.getItem('useremail');
const [email, setEmail] = useState(useremail);
const [tkn,setTkn]=useState(localStorage.getItem('tokenValue'));
const tokenRef = useRef(localStorage.getItem('tokenValue'));

const [password, setPassword] = useState('');
const [conpassword, setConpassword] = useState('');
const [user, setUser] = useState(null);
const [userData, setUserData] = useState({});
const [decodedValue, setDecodedValue] = useState(null);
const responseFacebook = (response) => {
	if (response && response.data) {
		// Access properties of the response object
		console.log(response.data);
	  } else {
		// Handle the case where the response is undefined or lacks expected data
		console.error('Invalid response from Facebook login');
	  }
	setUserData({
	  name: response.name,
	  email: response.email,
	  picture: response.picture.data.url,
	})
  };
  const handleLoginFailure = (error) => {
	console.log(error)
  };
  const handleLogout = () => {
	setUser(null)
  };
    const openModalLogin = () => { 
		
      setSelectedRowL();
      setShowModalL(true);  
    };

	useEffect(() => {
		if (trigger) {
			openModalLogin();
		}
	  }, [trigger]);
	useEffect(() => {
		
		function getData1() {

			if (tokenRef.current) {
				localStorage.setItem('tokenValue',tokenRef.current);
			}
			const storedToken = localStorage.getItem('tokenValue');
			const storedEmail = localStorage.getItem('useremail');
			
			console.log('Token*********************:', storedToken); // Debug log
			console.log('Token retrieved on mount:', tokenRef.current);
			console.log('Email*********************', storedEmail); // Debug log
			
			if(localStorage.getItem('useremail')&&localStorage.getItem('tokenValue'))
			{
		
				setEmail(useremail);
				setTkn(storedToken);
			}
			else{
				//alert(localStorage.getItem('tokenValue'));
				setEmail('Sign In /  Register');	
			}
		  }
		  getData1();
	},[]) ;
	const handleClick = () => {
		// Navigate to a different route programmatically
		navigate('/Messages');
	  };
	function handleSubmit(e) {
		e.preventDefault(); // Prevent the default form submission behavior
		// Here, you can perform actions like sending the data to a server or checking credentials.
		console.log('Email:', email);
		console.log('Password:', password);
		console.log('conpassword:', conpassword);
		const loginData = {
			"email":email,
			"password":password
		  };
		axios.post('https://b2b.travelxpo.in/api/customerAuth', loginData)
		.then((response) => {
		  // Handle the API response here
		  console.log('API Response:', response.data);
		  const token ='qwwert'; //response.data.accessToken;
		  //localStorage.setItem('tokenValue', token);
		  //alert("Success...");
		  setShowModalL(false); 
		  if(response?.data?.status === 200) {
		 // localStorage.setItem('tokenValue', token);
		  
		  }
		  
		  // If login is successful, you can redirect the user or perform other actions
		})
		.catch((error) => {
		  // Handle any API errors here
		  console.error('API Error:', error);
		});
	  }
  return (
    <div>
   
	<div className="searchview">
        <div className="top-bar" style={{ backgroundColor: 'blue' }}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-2">   
						<a className="navbar-brand" href="/"><img src="images/logo.png" alt="" /></a>
					</div>	
					<div className="col-md-7 p-0">
					  <nav class="navbar navbar-expand-lg">
						<div class="container-fluid">
							<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
							<span class="navbar-toggler-icon"></span>
							</button>
							<div class="collapse navbar-collapse" id="navbarScroll">
										<ul className="navbar-nav ml-auto">
											<li className="nav-item active">
												<a className="nav-link" href="/">Home <i class="fas fa-home"></i></a>
											</li>
											{/* <li className="nav-item active">
												<a className="nav-link" href="#">Hotels </a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#">Flights </a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#">Trains</a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#">Trains</a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#">Cars</a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#">Attractions & Tickets</a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#">Bundle & Save</a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#">Attractions</a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#">Rewards</a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#">Deals</a>
											</li> */}
										</ul>
									</div>
								
							</div>
						</nav>
					</div>
					<div className="col-md-3">
						<ul className="list-inline text-right pt-3">
							<li className="list-inline-item"><a href="javasript:void(0);">Help</a></li>
							<li className="list-inline-item"><a href="javasript:void(0);"><i className="fa fa-globe" aria-hidden="true"></i></a></li>
							{/* <li className="list-inline-item"><a className="bg-primary" href="#">Search Bookings</a></li> */}
							
							<li className="list-inline-item"><a  href="javasript:void(0);"  onClick={() => handleClick()}>Messages</a></li>
							<li className="list-inline-item"><a className="bg-light" href="javasript:void(0);"  onClick={() => openModalLogin()}>{email}</a></li>
							
						</ul>
					</div>
				</div>
			</div>
		</div>
		</div>	
		{showModalL && (
        <div  className="modal"
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div  className="modal-content" 
          style={{
            backgroundColor: 'white',
            padding: '5px',
            borderRadius: '5px',
            maxWidth: '450px', 
            maxHeight: '1000px', 
          }}
        >
          <div class="bg-white shadow-md rounded px-2 pt-2 pb-2 mb-2 w-full max-w-md">
		  <div className="row">
            <div className="col-lg-11" >
            </div>
            <div className="col-lg-1" >
      <button onClick={() => setShowModalL(false)} style={{color:"white",padding:"7px",fontSize:"20px",backgroundColor:"red",marginLeft:"-25px"}}><i className="fas fa-times"></i></button> 
      </div>
      </div>
		<h2 className="text-3xl font-bold mb-6 text-center text-white">
		  <span className="bg-gradient-to-r text-transparent from-blue-500 to-purple-500 bg-clip-text">
			<h5>Sign In/Sign Up</h5>
		  </span>
		</h2>
		{/* <form  onSubmit={handleSubmit}>
		  <div className="mb-6">
		
			<div className='mb-2'>
			  <input id="email" type="email" required className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
			  value={email}
			  onChange={(e) => setEmail(e.target.value)}
			  placeholder="Enter Your Email" />
			</div>
			<div className='mb-2'>
			  <input id="pwd" type="password" required className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
			   value={password}
			   onChange={(e) => setPassword(e.target.value)}
			  placeholder="Enter The Password" />
			</div>
			<div className='mb-2'>
			  <input id="cpwd" type="password" required className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
			   value={conpassword}
			   onChange={(e) => setConpassword(e.target.value)}
			  placeholder="Enter Confirm Password" />
			</div>
		  </div>
		  <div class="flex items-center justify-center">
			<button type="submit" className="bg-gradient-to-r bg-submit from-blue-500 to-purple-500 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline w-full">
			  Login
			</button>
		  </div>
		  <div class="text-center mt-4">
			<a href="#" className="text-gray-600 hover:underline">Forgot password?</a>
		  </div>
		</form> */}
		<div className="mt-4">
		  {/* <p className="text-center text-gray-600">More Sign In Methods</p> */}
		  
		  <div className="flex justify-center mt-2">
		  {userData.name ? (
      <div>
        <img src={userData.picture} alt={userData.name} />
        <p>Welcome, {userData.name}</p>
        <p>Email: {userData.email}</p>
      </div>
    ) : (
      <FacebookLogin
        appId="245645985119952"
        autoLoad={false}
        fields="name, email, picture"
        callback={responseFacebook}
		cssClass="bg-gradient-to-r btnfacebook from-blue-500 to-purple-500 hover:from-blue-700 hover:to-purple-700 text-white font-bold  rounded focus:outline-none focus:shadow-outline w-full" // Apply your custom class here
        icon={<i className="fab fa-facebook-f custom-icon"></i> }
      />

    )}
	<GoogleOAuthProvider clientId="68182157604-1i0cok90p4ji1o9n2t808u9udlfg6ko3.apps.googleusercontent.com">
	<GoogleLogin
  onSuccess={credentialResponse => {
	console.log("google data");
   
	const jwtdetails=jwt_decode(credentialResponse.credential);
	console.log(jwtdetails);
	setDecodedValue(jwtdetails);
	console.log(jwtdetails.email);
	const data={
		"email":jwtdetails.email,
		"username":jwtdetails.name
	 };
	 localStorage.setItem('useremail', jwtdetails.email);
	 localStorage.setItem('tokenValue', '');
	 if (jwtdetails) {
		axios.post('https://api.travelxpo.in/auth/social-login', data)
        .then((responseqt) => {
          console.log("Login info---" + JSON.stringify(responseqt.data));
          if (responseqt.data.status === 200) {
            
            console.log("Social Media Login ---" + JSON.stringify(responseqt.data));
			localStorage.setItem('tokenValue', responseqt.data.accessToken);
			localStorage.setItem('useremail', responseqt.data.email);
			sessionStorage.setItem('emailll',responseqt.data.email);
			setEmail(responseqt.data.email);
			setShowModalL(false);
          } else {
            //alert(responseqt.data);
			setShowModalL(false);
          }
        }) 
        .catch((error) => {
          if (error.isAxiosError) {
            // Handle AxiosError here
            console.error(error.response); // Error response
            console.error(error.request); // Request that caused the error
            console.error(error.message); // Error message
          } else {
            console.error(error);       
          }
        });
		   
		}



	// if (jwtdetails) {
	// 	fetch('http://3.108.180.21:4000/auth/social-login', req)
	// 	  .then((response) => response.json())
	// 	  .then((data) => {
	// 		// Handle the API response here
	// 		console.log("API Response:", data);
	// 	  })
	// 	  .catch((error) => {
	// 		// Handle any errors here
	// 		console.error('Error:', error);
	// 	  });
	//   }





	}}
	onError={() => {
	  console.log('Login Failed');
	}}
/>
	</GoogleOAuthProvider>
			{/* <a href="#" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2">
			  <i className="fab fa-facebook-f"></i>
			</a>
			<a href="#" class="bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded mx-2">
			  <i className="fab fa-twitter"></i>
			</a>
			<a href="#" className="bg-green-400 hover:bg-green-500 text-white font-bold py-2 px-4 rounded mx-2">
			  <i className="fab fa-weixin"></i>
			</a>
			<a href="#" className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mx-2">
			  <i className="fab fa-google"></i>
			</a>
			<a href="#" className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mx-2">
			  <i className="fab fa-apple"></i>
			</a> */}
		  </div>
		</div>
		
		<p className="text-center tremstext text-gray-600 mt-4">By signing in or registering, you are deemed to have agreed to the Travexpo. <a href="#" class="ibu_login_c_link privacy_policy_link_txt" target="_blank">Terms and Conditions</a> and <a href="#" class="ibu_login_c_link privacy_policy_link_txt" target="_blank">Privacy Statement</a></p>
	  </div>

           
      </div>

          </div>
        </div>
      )}  
    </div>
  )
}

export default TopbarSearch
