import React, { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
const FlightList = () => {
  const [flights, setFlights] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const location = useLocation();
    const jsonResponse = location.state?.responsee;
    const [response,setResponse] = useState(jsonResponse);

  useEffect(() => {
    if (jsonResponse && jsonResponse.Response && jsonResponse.Response.Results) {
      const processedFlights = processFlights(jsonResponse.Response.Results[0]);

      setFlights(processedFlights);
      setResponse(prevState => ({
        ...prevState,
        Response: {
          ...prevState.Response,
          Results: [processedFlights]
        }
      }));
    }
  }, [jsonResponse]);

  const processFlights = (results) => {
    const flightMap = new Map();
  
    results.forEach(result => {
      const segments = result.Segments[0];
      const firstSegment = segments[0];
      const flightNumber = `${firstSegment.Airline.AirlineCode}${firstSegment.Airline.FlightNumber}`;
  
      if (!flightMap.has(flightNumber)) {
        flightMap.set(flightNumber, {
          ...result,
          Options: []
        });
      }
  
      // Add this result as an option
      flightMap.get(flightNumber).Options.push({
        ResultIndex: result.ResultIndex,
        Fare: result.Fare,
        FareBreakdown: result.FareBreakdown,
        LastTicketDate: result.LastTicketDate,
        TicketAdvisory: result.TicketAdvisory,
        FareRules: result.FareRules,
        AirlineCode: result.AirlineCode,
        ValidatingAirline: result.ValidatingAirline,
        FareClassification: result.FareClassification
      });
    });
  
    return Array.from(flightMap.values());
  };
  
  // Helper function to calculate total duration
  const calculateTotalDuration = (segments) => {
    const firstDeparture = new Date(segments[0].Origin.DepTime);
    const lastArrival = new Date(segments[segments.length - 1].Destination.ArrTime);
    return (lastArrival - firstDeparture) / (1000 * 60); // Duration in minutes
  };

  const handleFlightSelect = (flight) => {
    setSelectedFlight(flight);
  };

  const handleOptionSelect = (flight, option) => {
    console.log("Selected flight:", flight.Segments[0][0].Airline.FlightNumber);
    console.log("Selected option:", option.ResultIndex);
    // Handle the selection here
  };

  return (
    <div>
     
      {response ? (
        <div>
          <h2>Data received:</h2>
          <pre>{JSON.stringify(flights, null, 2)}</pre>
        </div>
      ) : (
        <p>No data received</p>
      )}
 {/* <h2>Available Flights</h2>
 {flights.map((flight, index) => (
        <div key={index} style={{border: '1px solid #ccc', margin: '10px', padding: '10px'}}>
          <h3>{flight.Segments[0][0].Airline.AirlineName} - {flight.Segments[0][0].Airline.FlightNumber}</h3>
          <p>Departure: {new Date(flight.Segments[0][0].Origin.DepTime).toLocaleString()}</p>
          <p>Arrival: {new Date(flight.Segments[0][0].Destination.ArrTime).toLocaleString()}</p>
          
         <h4>Available Options:</h4>
          {flight.Options.map((option, optionIndex) => (
            <div key={optionIndex} style={{marginBottom: '10px'}}>
              <p>Published Fare: ₹{option.Fare.PublishedFare}</p>
              <p>Result Index: {option.ResultIndex}</p>
              <button onClick={() => handleOptionSelect(flight, option)}>Select This Option</button>
            </div>
          ))} 
        </div>
      ))}
    */}

    </div>
  );
};

export default FlightList;