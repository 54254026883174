import React, { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import TopbarSearch from './TopbarSearch';
import axios from 'axios';
const Messages = () => {
    const location = useLocation();
    const [rtnmsg, setRtnmsg] = useState(""); 
    const [ordid, setOrdid] = useState("");
    const [ordstr, setOrdstr] = useState(0);
    const [prc, setPrc] = useState(0);
    const [ibobdata, setIbobdata] = useState(0);
    const navigate = useNavigate();
    const [basefare, setBasefare] = useState(0.00);
    const [servicefare, setServicefare] = useState(0.00); 
    const [totalfare, setTotalfare] = useState(0.00);
    const [value, setValue] = useState(null);
    const [responsebook, setResponsebook] = useState(null);
    const [responsebookreturn, setResponsebookreturn] = useState(null);
    const [email, setEmail] = useState('Please Sign In');
    const [mobile, setMobile] = useState('');
    const [ticket, setTicket] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [mydiv, setMydiv] = useState("<p>Passengers Ticket Details</p>");
    const [selectedTickets, setSelectedTickets] = useState([]);
    const [selectedPNR, setSelectedPNR] = useState([]);
    const [selectedBookingID, setSelectedBookingID] = useState([]);
    const [comments, setComments] = useState("Cancelation Request");
    const [responseMessage, setResponseMessage] = useState(null);
    const [responseCancels, setResponseCancels] = useState(null);
    const [cancelsLength, setCancelsLength] = useState(0);
    const [trigger, setTrigger] = useState(0);
    const option11 = { year: 'numeric', month: 'long', day: 'numeric' };
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    let passString=sessionStorage.getItem('passString');
      useEffect(() => { 
          const mailid=localStorage.getItem('useremail');
          if (mailid !== null && mailid !== undefined) {
        //  setEmail(mailid);
          console.log(mailid);
            if (mailid!=='Please Sign In') {
             setEmail(mailid);
                axios
                    .post("https://b2b.travelxpo.in/api/get-Allbookings", {
                      "email":mailid
                    })
                    .then((response) => {
                      
                        console.log("API Response:", JSON.stringify(response.data));
                        setTicket(response.data);
                    })

                    .catch((error) => {
                      alert(error);
                     });
            } else {
                console.error('Encrypted Response not found in the request body.');
            }
          }else{
            setTrigger((trigger) => trigger + 1);
           // window.location.reload();
          }
    }, []);
   
    useEffect(() => {
  
          function getData1() {
        const ob1=localStorage.getItem('flightchargeOb');
        const prob1=localStorage.getItem('price');
        const ib1=localStorage.getItem('flightchargeIb');
        const prib1=localStorage.getItem('priceIb');
        let baseob1=0;
        let totpriceob1=0;
        let baseib1=0;
        let totpriceib1=0;
        if(ob1)
        {baseob1=ob1;}
        if(ib1)
        {baseib1=ib1;}
        if(prob1)
        {totpriceob1=prob1;}
        if(prib1)
        {totpriceib1=prib1;}
        let totalbasefare=parseFloat(baseob1)+parseFloat(baseib1);
        let totalprice=parseFloat(totpriceob1)+parseFloat(totpriceib1);
        let serviceprice=parseFloat(totalprice)-parseFloat(totalbasefare);
        
        setBasefare(totalbasefare);
        setServicefare(serviceprice);
        setTotalfare(totalprice);
  
            }
            getData1();
        
      },[]) ;
 
   const handleClickSend = (email,id,mobile) =>{
    //alert(id);
   }
    const handleClickView = (pnr,booking_id,mobilenum) => {
      if (pnr!=='REQUESTED' && booking_id !== 0) {
        setSelectedPNR(pnr);
        setSelectedBookingID(booking_id);
        setMobile(mobilenum);
        setSelectedTickets([]);
        //alert(booking_id);
           axios
               .post("https://b2b.travelxpo.in/api/generateTicket", {
                 "pnr":pnr,
                 "booking_id":booking_id
               })
               .then((response) => {
                try {
                  let responseData = response.data;
                  setResponseData(responseData);
                  axios
                      .post("https://b2b.travelxpo.in/api/getCancels", {
                        "pnr":pnr,
                        "booking_id":booking_id
                      })
                          .then((responc) => {
                            try {
              
                              let responseCanc = responc.data;
                             // alert(responseCanc.length);
                              setCancelsLength(responseCanc.length);
                              setResponseCancels(responseCanc);
                              } catch (error) {
                                  alert(error);
                              }
                          })
                  axios
                      .post("https://b2b.travelxpo.in/api/getMessages", {
                        "pnr":pnr,
                        "booking_id":booking_id
                      })
                          .then((respon) => {
                            try {
              
                              let responseMess = respon.data;
                            setResponseMessage(responseMess);
                            } catch (error) {
                                alert(error);
                            }
                          })
              } catch (error) {
                  // Handle the parsing error
                  console.error('Error accessing PNR:', error);
              }
              })
               .catch((error) => {
                alert(error);
                 console.log('Response Data:', error.response.data);
                });
       } else {
        
           console.error('Encrypted Response not found in the request body.');
       }


    };
    const isTicketSelected = (ticketId) => {

    return selectedTickets.some((selectedTicket) => selectedTicket.ticket_id === ticketId);
  };
  const isSelectedTickets = (ticketId) => {
    return (
      Array.isArray(selectedTickets) &&
      selectedTickets.some((selectedTicket) => selectedTicket.ticket_id === ticketId)
    );
  };
    const handleCheckboxChange = (ticketId, passengerName, ticketNumber) => {
     // alert(JSON.stringify(selectedTickets));
      const isTicketSelected = isSelectedTickets(ticketId);
     // alert(isTicketSelected);
      if (isTicketSelected) {
       
        // If the ticketId is already in the selectedTickets array, remove it
        const updatedTickets = selectedTickets.filter(
          (selectedTicket) => selectedTicket.ticket_id !== ticketId
        );
        setSelectedTickets(updatedTickets);
      } else {
  
        // If the ticketId is not in the selectedTickets array, add it
        const updatedTickets = [
          ...selectedTickets,
          { ticket_id: ticketId, passengerName, ticketNumber },
        ];
        
        setSelectedTickets(updatedTickets);
      }
    };

    const handleClickSaveComments = () => {
      
      if (comments!=='') {
      axios
               .post("https://b2b.travelxpo.in/api/saveTicketComments", {
                 "pnr":selectedPNR,
                 "comments":comments,
                 "booking_id":selectedBookingID,
               })
               .then((response) => {
                if (response.data && response.data.success) {
                  // Handle success
                  alert('Send Comment successfully!');
                  
                  axios
                  .post("https://b2b.travelxpo.in/api/getMessages", {
                    "pnr":selectedPNR,
                    "booking_id":selectedBookingID
                  })
                      .then((respon) => {
                        try {
          
                          let responseMess = respon.data;
                        setResponseMessage(responseMess);
                        } catch (error) {
                            alert(error);
                        }
                      })



                } else {
                  // Handle other cases (e.g., error)
                  alert('Request failed.');
                }
              
              })
               .catch((error) => {
                //alert(error.response.data);
                 console.log('Response Data:', error.response.data);
                });
      }
      else{
        alert('Please Enter Suitable comments before send');
      }

    }
    const handleClickSave = () => {
   //alert(JSON.stringify(selectedTickets));
   
   //alert(comments);
   //alert(selectedTickets.length+"...."+comments.length);
    if (selectedTickets.length > 0 ) {
      const  ss={
        "email":email,
        "mobile":mobile,
        "pnr":selectedPNR,
        "comments":comments,
        "booking_id":selectedBookingID,
        "ticketdata":selectedTickets
      }
     // alert(JSON.stringify(ss));
      axios
          .post("https://b2b.travelxpo.in/api/saveTicket", ss)
          .then((response) => {
              if (response.data && response.data.success) {
                  alert('Request successfully Saved!');
                  window.location.reload();
              } else {
                  // Handle other cases (e.g., error)
                  alert('Request failed.');
              }
          })
          .catch((error) => {
              alert(error);
              console.error('Error:', error);
          });
  } else {
      alert('Please Choose Tickets to cancel and send with valid Comments');
  }
  


    };
    const handleTextAreaChange = (event) => {
      // Update the state variable 'comments' when the text changes
      setComments(event.target.value);
    }; 
    const renderTicketTable = (data) => {
      if (!data) {
        // If data is null or undefined, return a placeholder or handle it accordingly
        return <p>Loading...</p>;
    }
else{
      return (
          <table>
              <thead>
                  <tr>
                      
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>PNR</th>
                      <th>BookingID</th>
                      <th>Price</th>
                      <th>Departure Date</th>
                      <th>Origin</th>
                      <th>Destination</th>
                      <th>Ticket</th>
                      <th>Action</th>
                      {/* Add more columns as needed */}
                  </tr>
              </thead>
              <tbody>
              {data.map((ticket) => (
                
    <tr key={ticket.id} style={{ height: "30px" }}>
      <td>{ticket.email}</td>
      <td>{ticket.mobile}</td>
      <td>{ticket.PNR}</td>
      <td>{ticket.booking_id}</td>
      <td>{ticket.total_ticket_fare}</td>
      <td>{new Date(ticket.departure_datetime).toLocaleTimeString([], options)}</td>
      <td>{ticket.departure_citycode}</td>
      <td>{ticket.arrivel_citycode}</td>
      <td>{ticket.isLCC ? 'LCC' : 'nonLCC'}</td>
      <td>
      { ticket.PNR !=='	REQUESTED' && ticket.booking_id !==0 && (      
        <button
          type="button"
          onClick={() => handleClickView(ticket.PNR, ticket.booking_id,ticket.mobile)}
          className="btn btn-info"
          style={{ height: "27px" }}
        >
          View Details
        </button>
      )}
       { ticket.PNR ==='	REQUESTED' || ticket.booking_id ===0 && (      
        <button
          type="button"
          onClick={() => handleClickSend(ticket.email, ticket.id,ticket.mobile)}
          className="btn btn-primary"
          style={{ height: "27px" }}
        >
          Send Message
        </button>
      )}
      </td>
      {/* Add more cells as needed */}
    </tr>
  ))}
              </tbody>
          </table>
      );
     }
  };
    return (
     <div>
      <TopbarSearch  trigger={trigger}  />
  <section class="bg-white"> 
    <div className="container mb-12">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
                  <div className="col-lg-12 detailsview">
                    <div className="card">
                        <div className="card-body">
                          <h4>FIND YOUR TICKET DETAILS FROM YOUR EMAIL ID</h4>
                        </div>
                        <div className="cardsection">
                          <div className="row">
                            <div className="col-lg-7 col-4">
                              <p>{email}</p>
                            </div>
                            <div className="col-lg-3 col-6 text-end linkssec">
                              <p></p>
                            </div>
                            <div className="col-lg-2 text-end">
                            <a href="/Messages"  ><button className="btn btn-dark"> FIND TICKETS</button></a>
                            </div>
                          </div>
                          
                        </div>
                    </div>
                 </div>
            </div>
            <div className="row">
                  <div className="col-lg-12 detailsview">
                    <div className="card">
                      
                        {renderTicketTable(ticket)}
                        
                    </div>
                 </div>
            </div>
        </div>
  
        <div className="col-lg-12">
       
                <div className="row">
                        <div className="col-lg-12 detailsview">
                          <div className="card">
                              
                              {/* <div dangerouslySetInnerHTML={{ __html: mydiv }}> */}
                              <div>
                              {responseData && responseData.length > 0 && (
                               
                              <table>
        <thead>
        <tr>
            <th colSpan={"6"} style={{fontSize:"16px",fontWeight:"normal"}}>Passengers Ticket Details <span style={{color:"blue"}}>(PNR:{selectedPNR},Booking ID:{selectedBookingID})</span></th>
        </tr>
          <tr>
            <th style={{width:"20%"}}>Name</th>
            <th style={{width:"20%"}}>Date of Birth</th>
            <th style={{width:"20%"}}>Passport Number</th>
            <th style={{width:"20%"}}>Pax Type</th>
            <th style={{width:"10%"}}>Ticket ID</th>
            <th style={{width:"10%"}}>Ticket Number</th>
            <th style={{width:"20%"}}>Action</th>
            {/* Add more headers as needed */}
          </tr>
        </thead>
        <tbody>
          {responseData.map((passenger, index) => (
            <tr key={index}>
              <td>{`${passenger.FirstName} ${passenger.LastName}`}</td>
              <td>{new Date(passenger.DateOfBirth).toLocaleDateString('en-US', option11)}</td>
              <td>{passenger.PassportNo}</td>
              <td> {(() => {
    switch (passenger.PaxType) {
      case 1:
        return 'Adult';
      case 2:
        return 'Child';
      case 3:
        return 'Infant';
      default:
        return 'Unknown';
    }
  })()}</td>
              <td>{passenger.Ticket.TicketId}</td>
              <td>{passenger.Ticket.TicketNumber}</td>
              <td><input type="checkbox" 
             onChange={() =>
               handleCheckboxChange(
                 passenger.Ticket.TicketId,
                 `${passenger.FirstName} ${passenger.LastName}`,
                 passenger.Ticket.TicketNumber
               )
             }
               /></td>
              {/* Add more cells as needed */}
            </tr>
          ))}
        </tbody>
      </table>
       )}  
                             
                              </div>
      
                          </div>
                       </div>

                       <div className="col-lg-12 detailsview">
                          <div className="card">
                              
                              {/* <div dangerouslySetInnerHTML={{ __html: mydiv }}> */}
                              <div>
                              {responseCancels && responseCancels.length > 0 && (
                               
                              <table>
        <thead>
        <tr>
            <th colSpan={"6"} style={{fontSize:"16px",fontWeight:"normal"}}>Cancellation Applied Passengers</th>
        </tr>
          <tr>
            <th style={{width:"20%"}}>Name</th>
            <th style={{width:"10%"}}>Ticket ID</th>
            <th style={{width:"10%"}}>Ticket Number</th>
            <th style={{width:"10%"}}>PNR</th>
            <th style={{width:"10%"}}>Booking ID</th>
            <th style={{width:"20%"}}>Request Date</th>
            <th style={{width:"20%"}}>Current Status</th>
            <th style={{width:"30%"}}>Remarks</th>
            {/* passengername ticketid ticket_number pnr_number booking_id request_date status */}
            {/* Add more headers as needed */}
          </tr>
        </thead>
        <tbody>
          {responseCancels.map((cancels, index) => (
            <tr key={index}>
              <td>{cancels.passengername}</td>
              <td>{cancels.ticketid}</td>             
              <td>{cancels.ticket_number}</td>
              <td>{cancels.pnr_number}</td>
              <td>{cancels.booking_id}</td>
              <td>{new Date(cancels.request_date).toLocaleDateString('en-US', option11)}</td>
              <td>{cancels.status}</td>
              <td>{cancels.comments}</td>
            </tr>
          ))}
        </tbody>
      </table>
       )}  
                             
                              </div>
      
                          </div>
                       </div>






                       <div className="card-body" style={{textAlign:"left"}}>
                        <h5>Comments</h5>
                        <textarea
      style={{ height: '100px', width: '100%' }}
      value={comments}
      onChange={handleTextAreaChange}
    />
                        <br />
                       
                        { cancelsLength >0 && (
                        <div style={{textAlign:"right"}}>
                          <input type="button" className="btn btn-primary" onClick={handleClickSaveComments} value="Send Comments" />
                          </div>
                        )}
                         {cancelsLength===0 && (
                        <div style={{textAlign:"right"}}>
                          <input type="button" className="btn btn-primary" onClick={handleClickSave} value="Save" />
                          </div>
                        )}
                        </div>

                        {responseMessage && responseMessage.length > 0 && (
                        <div className="card-body" style={{backgroundColor:"#e3efee",padding:"50px"}}>

                        <h5>Previous Comments</h5>
                        {responseMessage.map((comments, index) => (
     
                        <div key={index}>

{comments.customer_comments !== null && (
  <div className="card-body" style={{ textAlign: "left" }}>
    <textarea
      style={{ height: '50px', width: '60%' ,backgroundColor:"white"}}
      value={comments.customer_comments}
    /><br />
    <span style={{ fontSize: "9px" }}>Your Comments on: {new Date(comments.customer_date).toLocaleTimeString([], options)}</span>
  </div>
)}
                        {comments.txpo_comments !== null && (
                        <div style={{textAlign:"right"}}>
                        
                        <textarea
      style={{ height: '50px', width: '60%',backgroundColor:"#e7e6b5" }}
      value={comments.txpo_comments}
      
    /><br /><span  style={{ fontSize: "9px" }}>Travelxpo Comments on: {new Date(comments.txpo_date).toLocaleTimeString([], options)}</span>
                          
                          </div>
                          )}
                



                          </div>
 ))}
                        </div>
                        )}
                        
                  </div>
                  
                 
          </div>
          
        
      </div>
      
    </div>

    </section> 
    <footer className="section footer-classNameic">
    <div className="container">
      
      <div className="row row-50">
        <div className="col-lg-5 col-sm-6 wow fadeInUp brandlogo">
         <a className="brand" href="index.html"><img className="brand-logo-light" src="images/logo.png" alt="" width="200" height="60" /></a>
        </div>
        <div className="col-lg-4 col-sm-6 d-sm-flex flex-column wow fadeInUp">
          <h6 className="heading-6">useful links</h6>
             <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 ">
                  <ul className="list-marked-2">
                    <li><a href="#">Flights</a></li>
                    <li><a href="#">International Flights</a></li>
                    <li><a href="#">Hotels</a></li>
                    <li><a href="#">International Hotels</a></li>
                    <li><a href="#">Domestic Airline</a></li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 ">
                  <ul className="list-marked-2">
                    <li><a href="#">Indigo Airline</a></li>
                    <li><a href="#">Air Asia</a></li>
                    <li><a href="#">Spicejet</a></li>
                    <li><a href="#">Go Air</a></li>
                    <li><a href="#">Air India</a></li>
                  </ul>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-sm-6 wow fadeInUp">
          <ul className="d-flex socialmedia">
            <li><a href="#"><img src="images/icons/fb.png" alt="" className="img-fluid" /></a></li>
            <li><a href="#"><img src="images/icons/insta.png" alt="" className="img-fluid" /></a></li>
            <li><a href="#"><img src="images/icons/linkendin.png" alt="" className="img-fluid" /></a></li>
            <li><a href="#"><img src="images/icons/twitter.png" alt="" className="img-fluid" /></a></li>
            </ul>
        </div>
       </div>
    </div> 
    {/* <!--+rights--> */}
   </footer> 
   </div>
    )
  }
  
export default Messages